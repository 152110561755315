import Link from 'next/link';
import PropTypes from 'prop-types';
import CustomImage from '@/components/blog/CustomImage/CustomImage';
import classNames from 'classnames';
import styles from './BlogPostCard.module.scss';

const propTypes = {
  post: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.shape({}).isRequired,
    description: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    minutesToRead: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string,
  withDescription: PropTypes.bool,
};

function BlogPostCard({ post, className, withDescription = true }) {
  console.log(post);
  return (
    <Link
      href={`/blog/${post.slug.current}`}
      className={classNames(styles.blogPostCard, className)}
    >
      <CustomImage
        sanityImage={post.image}
        contained
        className={styles.blogPostCardImage}
      />
      <h2>{post.title}</h2>
      {withDescription && (
        <p className={styles.blogPostCardDescription}>{post.description}</p>
      )}
      <p className={styles.blogPostCardInfo}>
        {post.tags[0].label} • {`${post.minutesToRead} min`} •{' '}
        {new Date(post.publishedAt).toLocaleDateString('en-US')}
      </p>
    </Link>
  );
}

BlogPostCard.propTypes = propTypes;
export default BlogPostCard;
