import BlogPostCard from '@/components/global/PostCard/BlogPostCard';
import { Button, Icon } from '@/components/standalone';
import Carousel from '@/components/standalone/Carousel/Carousel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './BlogSection.module.scss';

const propTypes = {
  posts: PropTypes.shape({
    highlightedPost: PropTypes.shape(),
    latestPosts: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

function BlogSection({ posts }) {
  const { highlightedPost, latestPosts } = posts;
  const sidePosts = latestPosts.filter(
    (post) => post._id !== highlightedPost._id,
  );
  return (
    <div className={classNames('section', styles.blogSection)}>
      <div className="max-content-width-small">
        <div className={styles.blogSectionHeader}>
          <div className={styles.blogSectionTitle}>
            <div className="tb-tag">
              <Icon name="Rss" />
              Blog
            </div>
            <h2>
              Catch up on what&apos;s
              <br /> happening in web3 security
            </h2>
          </div>
          <div>
            <Button href="/blog" type="link" mode="primary" icon="ArrowRight">
              Read the Trustblock blog
            </Button>
          </div>
        </div>
        <Carousel className={styles.blogSectionCarousel} selectedItem={1}>
          <div className={styles.blogSectionPostContainer}>
            <BlogPostCard
              post={sidePosts[0]}
              className={styles.blogSectionPost}
            />
          </div>
          <div className={styles.blogSectionPostContainer}>
            <BlogPostCard
              post={highlightedPost}
              className={styles.blogSectionPost}
            />
          </div>
          <div className={styles.blogSectionPostContainer}>
            <BlogPostCard
              post={sidePosts[1]}
              className={styles.blogSectionPost}
            />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

BlogSection.propTypes = propTypes;
export default BlogSection;
